/* eslint-disable brace-style */
/* eslint-disable no-shadow */
/* eslint-disable indent */
import deliveryApi from '_api/delivery'
import {
  clearCurrentDeliveryAndDeliveryBooking,
  updateDeliveryCombinedPayment,
  updateDeliveryPaymentStatus,
} from '_redux/modules/delivery'
import { getListCombinedPayment, updateOrderCombinedPayment } from '_redux/modules/event'
import { handleDataOrder } from '_utils/function'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import {
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_HOST_CANCELLED,
  DELIVERY_HOST_PAID,
  DELIVERY_HOST_UNPAID,
} from '_utils/constant'

import orderApi from '_api/order'
import LoadingComponent from '_components/LoadingComponent'
import ModalDetailDelivery from '../components/ModalDetailDelivery'
import ModalMessage from '../components/ModalMessage'
import ModalPaymentScreen from '../components/ModalPaymentScreen'
import ModalWarning from '../components/ModalWarning'

export default function DeliveryList({ listDelivery, deliveryId }) {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const data = listDelivery
    .sort((a, b) => a.uName.localeCompare(b.uName))
    .sort((a, b) => b.createdAt - a.createdAt)

  const { eventAndOrderById, listCombinedPayments } = useSelector((state) => state.event)

  const [modalMessage, setModalMessage] = useState(false)
  const [modalPaymentScreen, setModalPaymentScreen] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [modalWarning, setModalWarning] = useState(false)
  const [dataModal, setDataModal] = useState({})
  const [dataModalDetail, setDataModalDetail] = useState({})
  const [loading, setLoading] = useState(true)

  const isMountedRef = useRef(true)

  const initListDelivery = async (_data) => {
    try {
      const listCombinedPaymentRef = Array.from(
        new Set(
          _data
            .map((element) => element.combinedPaymentRef)
            .filter((combinedPaymentRef) => combinedPaymentRef)
        )
      )

      if (listCombinedPaymentRef.length > 0) {
        await dispatch(getListCombinedPayment(listCombinedPaymentRef))
      }
    } catch (error) {
      if (isMountedRef.current) {
        addToast('Failed to initialize delivery list.', { appearance: 'error', autoDismiss: true })
      }
    } finally {
      if (isMountedRef.current) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    initListDelivery(listDelivery)

    return () => {
      isMountedRef.current = false
      dispatch(clearCurrentDeliveryAndDeliveryBooking())
    }
  }, [])

  const toggleMessage = () => setModalMessage(!modalMessage)

  const togglePaymentScreen = () => setModalPaymentScreen(!modalPaymentScreen)

  const toggleDetail = () => {
    setModalDetail(!modalDetail)
  }

  const toggleWarning = () => setModalWarning(!modalWarning)

  const updateDeliveryBookingStatus = async (_ref, _data) => {
    dispatch(updateDeliveryPaymentStatus(deliveryId, _ref, _data.status, _data.bookerStatus))
    await deliveryApi.updateDeliveryBookingStatus(_ref, _data)
    addToast('Updated status successfully', { appearance: 'success', autoDismiss: true })
  }

  const updateDeliveryBookerStatus = async (_ref, _data) => {
    dispatch(updateDeliveryPaymentStatus(deliveryId, _ref, _data.status, _data.bookerStatus))
    await deliveryApi.updateDeliveryBookerStatus(_ref, { bookerStatus: _data.bookerStatus })
    addToast('Updated status successfully', { appearance: 'success', autoDismiss: true })
  }

  const updateDeliveryCombinedPaymentAPI = async (_data) => {
    dispatch(
      updateDeliveryCombinedPayment(_data.combinedPaymentRef, _data.status, _data.placerStatus)
    )
    dispatch(updateOrderCombinedPayment(_data.combinedPaymentRef, _data.status, _data.placerStatus))
    await orderApi.updateInvoicesStatus(_data)
  }

  const onChangeSelect = async (e, _ref, _status, _bookerStatus, _combinedPaymentRef) => {
    try {
      const newStatus = Number(e.target.value)
      let updateData = {}

      // Case 1: Changing from Unpaid to Paid
      if (_status === DELIVERY_HOST_UNPAID && newStatus === DELIVERY_HOST_PAID) {
        updateData = { status: newStatus, bookerStatus: DELIVERY_BUYER_PAID }
      }
      // Case 2: Cancelling a paid or partially paid delivery
      else if (newStatus === DELIVERY_HOST_CANCELLED && _bookerStatus > DELIVERY_BUYER_UNPAID) {
        if (_combinedPaymentRef) {
          updateData = {
            combinedPaymentRef: _combinedPaymentRef,
            status: newStatus,
            placerStatus: DELIVERY_BUYER_REFUND,
          }
          await updateDeliveryCombinedPaymentAPI(updateData)
          return // Exit early as we've handled this case
        }
        updateData = { status: newStatus, bookerStatus: DELIVERY_BUYER_REFUND }
      }
      // Case 3: Uncancelling a refunded delivery
      else if (
        _status === DELIVERY_HOST_CANCELLED &&
        newStatus !== DELIVERY_HOST_CANCELLED &&
        _bookerStatus === DELIVERY_BUYER_REFUND
      ) {
        updateData = { status: newStatus, bookerStatus: DELIVERY_BUYER_PAID }
      }
      // Default case: Just update the status
      else {
        updateData = { status: newStatus, bookerStatus: _bookerStatus }
      }

      // Update the delivery booking status
      await updateDeliveryBookingStatus(_ref, updateData)
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const openMessageModal = (e, delivery) => {
    e.preventDefault()
    setDataModal(delivery)
    toggleMessage()
  }

  const openPaymentScreen = useCallback(
    (e, delivery) => {
      e.preventDefault()

      const { combinedPaymentRef } = delivery
      let listCombinedOrder = []
      let listCombinedDelivery = []

      if (combinedPaymentRef) {
        const combinedPayment = listCombinedPayments?.find(
          (item) => item.combinedPaymentRef === combinedPaymentRef
        )

        if (combinedPayment) {
          const { orderCombinedPayment, deliveryCombinedPayment } = combinedPayment

          listCombinedOrder = orderCombinedPayment
            .map((element) => {
              const { eid, ref: elementRef } = element
              const event = eventAndOrderById.find((item) => item.id === eid)
              if (!event) return null

              const { orders, adminCost, discount, deliveryCost, productIdList } = event
              const { listOrder: otherListOrder } = handleDataOrder(
                orders,
                adminCost,
                discount,
                deliveryCost,
                productIdList
              )

              return otherListOrder.find((item) => item.ref === elementRef)
            })
            .filter(Boolean)

          listCombinedDelivery = deliveryCombinedPayment
        }
      }

      setDataModal({ ...delivery, listCombinedOrder, listCombinedDelivery })
      togglePaymentScreen()
    },
    [listCombinedPayments, eventAndOrderById, setDataModal, togglePaymentScreen]
  )

  const handleInvalidPayment = async (_ref, _status, _combinedPaymentRef) => {
    try {
      const r = confirm('Are you sure this is invalid payment?')
      if (r === true) {
        if (_combinedPaymentRef) {
          const _data = {
            combinedPaymentRef: _combinedPaymentRef,
            status: DELIVERY_HOST_UNPAID,
            placerStatus: DELIVERY_BUYER_UNPAID,
          }
          await updateDeliveryCombinedPaymentAPI(_data)
        } else {
          const _data = { status: _status, bookerStatus: DELIVERY_BUYER_UNPAID }
          await updateDeliveryBookerStatus(_ref, _data)
        }
        togglePaymentScreen()
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      togglePaymentScreen()
    }
  }

  const handleShowDetail = (e, delivery) => {
    e.preventDefault()
    setDataModalDetail(delivery)
    toggleDetail()
  }

  const handleCancel = async (e, _ref, _status, _bookerStatus, _combinedPaymentRef) => {
    e.preventDefault()
    try {
      if (_status !== DELIVERY_HOST_CANCELLED && _bookerStatus > DELIVERY_BUYER_UNPAID) {
        if (_combinedPaymentRef) {
          const _data = {
            combinedPaymentRef: _combinedPaymentRef,
            status: DELIVERY_HOST_CANCELLED,
            placerStatus: DELIVERY_BUYER_REFUND,
          }
          await updateDeliveryCombinedPaymentAPI(_data)
        } else {
          const _data = { status: DELIVERY_HOST_CANCELLED, bookerStatus: DELIVERY_BUYER_REFUND }
          await updateDeliveryBookingStatus(_ref, _data)
        }
        toggleDetail()
        toggleWarning()
      } else {
        const _data = { status: DELIVERY_HOST_CANCELLED, bookerStatus: _bookerStatus }
        await updateDeliveryBookingStatus(_ref, _data)
        toggleDetail()
        toggleWarning()
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      toggleDetail()
      toggleWarning()
    }
  }

  const handleOpenWarning = (e, delivery) => {
    e.preventDefault()
    setDataModal(delivery)
    toggleWarning()
  }

  return (
    <div className='list-event'>
      <>
        {loading ? <LoadingComponent className='mt-5' /> : null}

        {!loading && data && data.length > 0
          ? data.map((delivery, index) => (
              <div key={index}>
                <div
                  className='list-event__item'
                  style={index === 0 ? { borderTop: '1px solid rgba(134, 131, 132, 0.9)' } : {}}
                >
                  <div className='row'>
                    <div className='col-3'>
                      <h4>
                        <a href='#' onClick={(e) => handleShowDetail(e, delivery)}>
                          {delivery.uName}
                        </a>
                      </h4>
                      {delivery.comment && (
                        <a href='#' onClick={(e) => openMessageModal(e, delivery)}>
                          <i className='icon-new'></i>
                        </a>
                      )}
                    </div>
                    <div className='col-4 text-center'>
                      ${delivery.dzPrice.toFixed(2)}
                      {delivery.bookerStatus === DELIVERY_BUYER_REFUND ? ' (Refunded)' : ''}
                    </div>
                    <div className='col-2 text-center'>
                      {delivery.bookerStatus === DELIVERY_BUYER_UNPAID && (
                        <span className='n-paid' style={{ paddingRight: 0 }}>
                          Not Paid
                        </span>
                      )}
                      {delivery.bookerStatus === DELIVERY_BUYER_PAID && (
                        <a
                          href='#'
                          className={delivery?.combinedPaymentRef ? 'paid-combined' : 'paid'}
                          data-toggle='modal'
                          data-target='#modal-paid'
                          onClick={(e) => openPaymentScreen(e, delivery)}
                        >
                          Paid
                        </a>
                      )}
                      {delivery.bookerStatus === DELIVERY_HOST_CANCELLED && (
                        <span className=''>Cancelled</span>
                      )}
                      {delivery.bookerStatus === DELIVERY_BUYER_REFUND && (
                        <span className=''>Refunded</span>
                      )}
                    </div>
                    <div className='col-3'>
                      <select
                        id='paymentStatus_0'
                        className='form-control-payment'
                        value={delivery.status}
                        onChange={(e) =>
                          onChangeSelect(
                            e,
                            delivery.ref,
                            delivery.status,
                            delivery.bookerStatus,
                            delivery.combinedPaymentRef
                          )
                        }
                        style={
                          delivery.status === DELIVERY_HOST_UNPAID
                            ? { color: '#ff6903' }
                            : delivery.status === DELIVERY_HOST_PAID
                            ? { color: '#51aaaa' }
                            : { color: '#7c797a' }
                        }
                      >
                        <option value={DELIVERY_HOST_CANCELLED}>Cancelled</option>
                        <option value={DELIVERY_HOST_UNPAID}>Unpaid</option>
                        <option value={DELIVERY_HOST_PAID}>Verified</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : null}
      </>
      {Object.keys(dataModal).length !== 0 && (
        <ModalMessage data={dataModal} modalMessage={modalMessage} toggleMessage={toggleMessage} />
      )}
      {Object.keys(dataModal).length !== 0 && (
        <ModalPaymentScreen
          data={dataModal}
          modalPaymentScreen={modalPaymentScreen}
          togglePaymentScreen={togglePaymentScreen}
          handleInvalidPayment={handleInvalidPayment}
        />
      )}
      {Object.keys(dataModal).length !== 0 && (
        <ModalWarning
          data={dataModal}
          title='delivery'
          modalWarning={modalWarning}
          toggleWarning={toggleWarning}
          handleCancel={handleCancel}
        />
      )}
      {Object.keys(dataModalDetail).length !== 0 && (
        <ModalDetailDelivery
          data={dataModalDetail}
          modalDetail={modalDetail}
          toggleDetail={toggleDetail}
          handleOpenWarning={handleOpenWarning}
          deliveryId={deliveryId}
        />
      )}
    </div>
  )
}
