/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-useless-return */
import eventApi from '_api/event'
import groupApi from '_api/group'
import orderApi from '_api/order'
import serviceApi from '_api/service'
import { clearDeliveryDetail } from '_redux/modules/delivery'
import { clearPastPurchasedMember } from '_redux/modules/group'
import {
  clearDataPurchaseDetail,
  clearDataPurchaseDetailByEid,
  clearMyOrder,
} from '_redux/modules/order'
import { DOMAIN, STATUS_PAID } from '_utils/constant'
import { getDomain, getUserInfo, setDomain } from '_utils/localData'
import { debounce } from 'lodash'
import { DEFAULT_KEY, generateCacheTTL } from 'redux-cache'

const GET_LIST_EVENTS = 'GET_LIST_EVENTS'
const GET_LIST_EVENT_GROUP_ID_BY_HOST = 'GET_LIST_EVENT_GROUP_ID_BY_HOST'
const GET_LIST_SCHEDULED_EVENTS = 'GET_LIST_SCHEDULED_EVENTS'
const DELETE_LIST_SCHEDULED_EVENTS = 'DELETE_LIST_SCHEDULED_EVENTS'
const GET_LIST_SCHEDULED_EVENTS_ERROR = 'GET_LIST_SCHEDULED_EVENTS_ERROR'
const GET_MY_EVENTS = 'GET_MY_EVENTS'
const GET_ALL_EVENTS_OPENING_REQUEST = 'GET_ALL_EVENTS_OPENING_REQUEST'
const GET_ALL_EVENTS_OPENING_SUCCESS = 'GET_ALL_EVENTS_OPENING_SUCCESS'
const GET_ALL_EVENTS_OPENING_FAIL = 'GET_ALL_EVENTS_OPENING_FAIL'
const GET_SHARE_EVENT_CODE = 'GET_SHARE_EVENT_CODE'
const CLEAR_EVENT = 'CLEAR_EVENT'
const DELETE_EVENT = 'DELETE_EVENT'
const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID'
const GET_EVENT_AND_ORDER_BUY_EID = 'GET_EVENT_AND_ORDER_BUY_EID'
const CREATE_EVENT = 'CREATE_EVENT'
const UPDATE_EVENT = 'UPDATE_EVENT'
const UPDATE_STATUS_EVENT = 'UPDATE_STATUS_EVENT'
const UPDATE_EVENT_ORDER = 'UPDATE_EVENT_ORDER'
const UPDATE_STATUS_ORDER = 'UPDATE_STATUS_ORDER'
const UPDATE_TOTAL_ORDER_COUNT_WHEN_ORDER = 'UPDATE_TOTAL_ORDER_COUNT_WHEN_ORDER'
const UPDATE_TOTAL_ORDER_COUNT_WHEN_CANCEL_ORDER = 'UPDATE_TOTAL_ORDER_COUNT_WHEN_CANCEL_ORDER'
const UPDATE_TOTAL_ORDER_COUNT_WHEN_UPDATE_ORDER = 'UPDATE_TOTAL_ORDER_COUNT_WHEN_UPDATE_ORDER'
const CLEAR_EVENT_ORDER = 'CLEAR_EVENT_ORDER'
const GET_COMBINED_PAYMENT = 'GET_COMBINED_PAYMENT'
const CLEAR_MY_EVENTS = 'CLEAR_MY_EVENTS'
const CLEAR_ALL_EVENTS = 'CLEAR_ALL_EVENTS'
const GET_EVENTS_CLOSE_PER_MONTH_BY_HOST = 'GET_EVENTS_CLOSE_PER_MONTH_BY_HOST'
const GET_EVENTS_COLLECTION_PER_MONTH_BY_HOST = 'GET_EVENTS_COLLECTION_PER_MONTH_BY_HOST'
const GET_LIST_EVENT_AND_DELIVERY = 'GET_LIST_EVENT_AND_DELIVERY'
const GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID = 'GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID'
const GET_LIST_EVENTS_UN_AUTH = 'GET_LIST_EVENTS_UN_AUTH'
const GET_ALL_EVENTS_BY_GROUP = 'GET_ALL_EVENTS_BY_GROUP'
const CLEAR_ALL_EVENTS_OPENING = 'CLEAR_ALL_EVENTS_OPENING'
const GET_LIST_EVENT_VALID = 'GET_LIST_EVENT_VALID'
const PAYMENT_SCHEDULE_EVENT = 'PAYMENT_SCHEDULE_EVENT'
const UPDATE_DELIVERY_PAYMENT_STATUS = 'UPDATE_DELIVERY_PAYMENT_STATUS'
const UPDATE_DELIVERY_COMMENT_DESKTOP = 'UPDATE_DELIVERY_COMMENT_DESKTOP'
const GET_LIST_EVENT_PUBLIC = 'GET_LIST_EVENT_PUBLIC'

const initialState = {
  [DEFAULT_KEY]: null,
  allEvents: [],
  myEvents: [],
  newGroup: null,
  eventById: null,
  eventAndOrderById: [],
  callNewAPIListOrder: false,
  loadingAllEventsOpening: true,
  errorAllEventsOpening: null,
  errorGetEventAndOrder: null,
  allScheduleEvent: [],
  allScheduleEventByGroupId: [],
  errorAllScheduleEvent: null,
  listCombinedPayments: [],
  listEventGroupIdByHost: [],
  listEventsClosePerMonthByHost: null,
  listEventsCollectionPerMonthByHost: null,
  listEventAndDelivery: [],
  validEvents: [], // for API 162,
  currentPlanScheduleEvent: null,
  loadingGetListEventOrder: true,
  loadingGetListCombinedPayment: true,
  loadingGetEventAndOrder: true,
  loadingGetEventGroupId: true,
}

export const updateOrderNote = (eventId, ref, note) => (dispatch, getState) => {
  const { eventAndOrderById } = getState().event

  const updatedEventAndOrderById = eventAndOrderById.map((item) => {
    if (item.id !== eventId) return item

    const updatedOrders = item.orders.map((order) =>
      order.ref === ref ? { ...order, comment: note } : order
    )

    return { ...item, orders: updatedOrders }
  })

  dispatch({
    type: UPDATE_EVENT_ORDER,
    payload: { eventAndOrderById: updatedEventAndOrderById },
  })
}

export const getListEventsInGroupPublic = () => async (dispatch) => {
  try {
    const { msgResp } = await eventApi.getListEventValid()

    dispatch({
      type: GET_LIST_EVENT_PUBLIC,
      payload: { eventsPublic: msgResp },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const paymentScheduleEvent =
  ({ packageId, packageType, startDate }) =>
  async (dispatch) => {
    try {
      const { msgResp } = await eventApi.paymentScheduleEvent({
        packageId,
        packageType,
        startDate,
      })

      if (msgResp?.paymentLink) {
        window.open(msgResp.paymentLink, '_blank')
      }

      dispatch({
        type: PAYMENT_SCHEDULE_EVENT,
        payload: { currentPlanScheduleEvent: msgResp },
      })
    } catch (error) {
      throw new Error(error?.msgResp || error.message)
    }
  }

export const getListEventValid = () => async (dispatch) => {
  try {
    const { msgResp } = await eventApi.getListEventValid()

    dispatch({
      type: GET_LIST_EVENT_VALID,
      payload: { validEvents: msgResp },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const getAllScheduleEventByGroupId = (groupId) => async (dispatch) => {
  try {
    const { msgResp: newAllScheduleEvent } = await eventApi.listMyScheduledEvents()
    const allScheduleEventByGroupId = newAllScheduleEvent?.filter(
      (item) => item.groupId === groupId
    )
    dispatch({
      type: GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID,
      payload: { allScheduleEventByGroupId },
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getListEventAndDelivery = (groupId) => async (dispatch) => {
  try {
    const { msgResp } = await eventApi.getListEventAndDelivery(groupId)

    dispatch({
      type: GET_LIST_EVENT_AND_DELIVERY,
      payload: { listEventAndDelivery: msgResp },
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getEventGroupIdByHost = (groupId, date) => async (dispatch) => {
  const userInfo = getUserInfo()
  let listEventGroupIdByHost = []
  try {
    const response = await eventApi.getListEventsByGroupId(groupId, date)

    if (!response.msgResp.length) {
      dispatch({
        type: 'GET_EVENT_AND_ORDER_BUY_EID',
        payload: {
          loadingGetEventAndOrder: true,
        },
      })
    }

    if (response.msgResp) {
      listEventGroupIdByHost = response.msgResp.filter(
        (_event) => _event.createdUserId === userInfo.id
      )
    }
    dispatch({
      type: GET_LIST_EVENT_GROUP_ID_BY_HOST,
      payload: { listEventGroupIdByHost },
    })
  } catch (error) {
    throw new Error(error.msgResp)
  } finally {
    dispatch({
      type: GET_LIST_EVENT_GROUP_ID_BY_HOST,
      payload: {
        loadingGetEventGroupId: false,
      },
    })
  }
}

export const callNewListOrder = () => async (dispatch) => {
  dispatch({
    type: GET_EVENT_AND_ORDER_BUY_EID,
    payload: { callNewAPIListOrder: true },
  })
}

export const getAllEventsOpening = async (dispatch, getState) => {
  dispatch({
    type: GET_ALL_EVENTS_OPENING_REQUEST,
    payload: { loadingAllEventsOpening: true },
  })
  try {
    const { event } = getState()
    const { allEvents } = event

    // Not call API from server
    if (allEvents && allEvents.length > 0) {
      dispatch({
        type: GET_ALL_EVENTS_OPENING_SUCCESS,
        payload: { loadingAllEventsOpening: false, allEvents },
      })
      return
    }

    // Call API from server
    let domainLocal = getDomain(DOMAIN)
    const parser = document.createElement('a')
    parser.href = process.env.REACT_APP_APP_URL

    if (!domainLocal && DOMAIN !== parser.hostname) {
      const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
      const { groupId } = msgResp

      domainLocal = groupId
      setDomain(DOMAIN, groupId)
    }

    const { msgResp: newAllEventsOpening } = await eventApi.getListEventsOpening(domainLocal)
    dispatch({
      type: GET_ALL_EVENTS_OPENING_SUCCESS,
      payload: { loadingAllEventsOpening: false, allEvents: newAllEventsOpening },
    })
  } catch (error) {
    dispatch({
      type: GET_ALL_EVENTS_OPENING_FAIL,
      payload: { loadingAllEventsOpening: false, errorAllEventsOpening: error },
    })
  }
}

export const clearAllEventsOpening = () => (dispatch) => {
  dispatch({
    type: CLEAR_ALL_EVENTS_OPENING,
    payload: { allEvents: [] },
  })
  // clearAllEventsOpeningLocal()
}

export const getListEventUnAuth = () => async (dispatch) => {
  try {
    const { msgResp } = await eventApi.getListEventUnAuth(10, 1)
    dispatch({
      type: GET_LIST_EVENTS_UN_AUTH,
      payload: { allEvents: msgResp.events },
    })
  } catch (e) {}
}

export const getScheduledEvent = async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allScheduleEvent } = event

    // Not call API from server
    if (allScheduleEvent && allScheduleEvent.length > 0) {
      return
    }

    // Call API from server
    let domainLocal = getDomain(DOMAIN)
    const parser = document.createElement('a')
    parser.href = process.env.REACT_APP_APP_URL

    if (!domainLocal && DOMAIN !== parser.hostname) {
      const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
      const { groupId } = msgResp

      domainLocal = groupId
      setDomain(DOMAIN, groupId)
    }
    const { msgResp: newAllScheduleEvent } = await eventApi.listMyScheduledEvents(domainLocal)

    dispatch({
      type: GET_LIST_SCHEDULED_EVENTS,
      payload: { allScheduleEvent: newAllScheduleEvent },
    })
  } catch (error) {
    dispatch({
      type: GET_LIST_SCHEDULED_EVENTS,
      payload: { errorAllScheduleEvent: error },
    })
  }
}

export const clearScheduledEvent = (dispatch) => {
  dispatch({
    type: DELETE_LIST_SCHEDULED_EVENTS,
    payload: { allScheduleEvent: [] },
  })
}

export const deleteScheduledEvent = (id) => (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allScheduleEvent } = event

    const newAllScheduleEvent = allScheduleEvent

    const index = newAllScheduleEvent.findIndex((item) => item.id === id)

    if (index !== -1) {
      newAllScheduleEvent.splice(index, 1)
    }

    dispatch({
      type: GET_LIST_SCHEDULED_EVENTS,
      payload: {
        allScheduleEvent: [...newAllScheduleEvent],
      },
    })
  } catch (error) {}
}

export const getMyEvents = async (dispatch, getState) => {
  try {
    const { event, group } = getState()
    const { myEvents, allEvents } = event
    const { myGroups } = group
    const userInfo = getUserInfo()
    if (myEvents?.length) {
      return
    }
    if (allEvents?.length > 0 && myEvents === null) {
      const newMyEvents = allEvents.filter((_event) => _event.createdUserId === userInfo.id)
      dispatch({ type: GET_MY_EVENTS, payload: { myEvents: newMyEvents } })
    } else {
      let events = []

      let domainLocal = getDomain(DOMAIN)
      const parser = document.createElement('a')
      parser.href = process.env.REACT_APP_APP_URL

      if (!domainLocal && DOMAIN !== parser.hostname) {
        const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
        const { groupId } = msgResp

        domainLocal = groupId
        setDomain(DOMAIN, groupId)
      }

      const { msgResp: listEvents } = await eventApi.getListMyEvents(domainLocal)
      for (let i = 0; i < listEvents.length; i++) {
        const _event = listEvents[i]
        orderApi.getListOrdersByEventId(_event.id).then(async (res) => {
          const newEvent = {
            ..._event,
            orders: res.msgResp,
          }
          if (!myGroups) {
            const { msgResp: groupInfo } = await groupApi.getById(_event.groupId)
            newEvent.groupName = groupInfo.name
          } else {
            events = events.map((item) => {
              item.groupName = myGroups.filter((l) => l.gid === item.groupId)[0]?.name
              return item
            })
          }
          events = [...events, newEvent]

          const newMyEvents = events.filter((item) => item.createdUserEmail === userInfo.email)
          dispatch({
            type: GET_MY_EVENTS,
            payload: { myEvents: newMyEvents },
          })
        })
      }
      const newMyEvents = listEvents.filter((_event) => _event.createdUserId === userInfo.id)
      dispatch({
        type: GET_MY_EVENTS,
        payload: { myEvents: [...newMyEvents] },
      })
    }
  } catch (error) {
    throw new Error(error.msgResp)
  }
}

export const getShareEventCode = (eventId) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents } = event
    const { msgResp } = await eventApi.getShareEventCode(eventId)
    const newMyEvents = myEvents
    const indexInMyEvents = newMyEvents.filter((item) => item.id === eventId)
    if (indexInMyEvents !== -1) {
      newMyEvents[indexInMyEvents].shareCode = msgResp.shareCode
      dispatch({
        type: GET_SHARE_EVENT_CODE,
        payload: { myEvents: [...newMyEvents] },
      })
    }
  } catch (error) {}
}

export const clearGroup = () => ({
  type: CLEAR_EVENT,
})

export const updateDataEventAndOrder = (eventId) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { eventAndOrderById } = event
    const newEventAndOrderById = eventAndOrderById
    const index = newEventAndOrderById.findIndex((item) => item.id === eventId)
    if (index !== -1) {
      newEventAndOrderById[index].updated = true
    }
    dispatch({
      type: UPDATE_EVENT_ORDER,
      payload: { eventAndOrderById: newEventAndOrderById },
    })
  } catch (error) {}
}

export const clearEventAndOrder = () => async (dispatch) => {
  dispatch({
    type: CLEAR_EVENT_ORDER,
    payload: { eventAndOrderById: [] },
  })
}

export const clearMyEvents = () => async (dispatch) => {
  dispatch({
    type: CLEAR_MY_EVENTS,
    payload: { myEvents: [] },
  })
}

export const clearAllEvents = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ALL_EVENTS,
    payload: { allEvents: [] },
  })
}

export const updateEventWhenOrder = (newOrder) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allEvents, myEvents } = event
    const { eid, details } = newOrder

    let count = 0
    for (const key in details) {
      if (Object.hasOwnProperty.call(details, key)) {
        count += details[key][0].quantity
      }
    }

    const indexEvent = allEvents?.findIndex((item) => item.id === eid)
    if (allEvents.length && indexEvent !== -1) {
      allEvents[indexEvent].totalOrderCount += count
    }

    const indexInMyEvents = myEvents?.findIndex((item) => item.id === eid)
    if (myEvents.length && indexInMyEvents !== -1) {
      myEvents[indexInMyEvents].totalOrderCount += count
    }

    dispatch({
      type: UPDATE_TOTAL_ORDER_COUNT_WHEN_ORDER,
      payload: {
        allEvents: [...allEvents],
        myEvents: [...myEvents],
      },
    })
  } catch (error) {}
}

export const updateTotalOrderCountWhenCancelOrder = (orderDetail) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allEvents, myEvents } = event
    const { event: eventDetail, productOrder } = orderDetail
    const totalOrderCount = productOrder?.allProductPerOrder.reduce((a, b) => a + b.pQuantity, 0)

    const indexInAllEvents = allEvents?.findIndex((item) => item.id === eventDetail.id)
    if (allEvents.length && indexInAllEvents !== -1) {
      allEvents[indexInAllEvents].totalOrderCount -= totalOrderCount
    }

    const indexInMyEvents = myEvents?.findIndex((item) => item.id === eventDetail.id)
    if (myEvents.length && indexInMyEvents !== -1) {
      myEvents[indexInMyEvents].totalOrderCount -= totalOrderCount
    }
    dispatch({
      type: UPDATE_TOTAL_ORDER_COUNT_WHEN_CANCEL_ORDER,
      payload: {
        allEvents: [...allEvents],
        myEvents: [...myEvents],
      },
    })
  } catch (error) {}
}

export const updateTotalOrderCountWhenUpdateOrder = (orderDetail) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allEvents, myEvents } = event
    const { eid, details, initTotalOrderCount } = orderDetail

    let count = 0
    for (const key in details) {
      if (Object.hasOwnProperty.call(details, key)) {
        count += details[key][0].quantity
      }
    }

    count -= initTotalOrderCount

    const indexEvent = allEvents?.findIndex((item) => item.id === eid)
    if (allEvents.length && indexEvent !== -1) {
      allEvents[indexEvent].totalOrderCount += count
    }

    const indexInMyEvents = myEvents?.findIndex((item) => item.id === eid)
    if (myEvents.length && indexInMyEvents !== -1) {
      myEvents[indexInMyEvents].totalOrderCount += count
    }
    dispatch({
      type: UPDATE_TOTAL_ORDER_COUNT_WHEN_UPDATE_ORDER,
      payload: {
        allEvents: [...allEvents],
        myEvents: [...myEvents],
      },
    })
  } catch (error) {}
}

export const updateOrderStatus =
  (eventId, ref, status, buyerStatus = null) =>
  async (dispatch, getState) => {
    try {
      const { event } = getState()
      const { eventAndOrderById } = event

      const updatedEventAndOrderById = eventAndOrderById.map((item) => {
        if (item.id !== eventId) return item

        const updatedOrders = item.orders.map((order) =>
          order.ref === ref ? { ...order, status, buyerStatus } : order
        )

        return { ...item, orders: updatedOrders }
      })

      dispatch(clearDataPurchaseDetailByEid(eventId))
      dispatch(clearMyOrder())
      dispatch(clearPastPurchasedMember())
      dispatch({
        type: UPDATE_STATUS_ORDER,
        payload: {
          eventAndOrderById: updatedEventAndOrderById,
          callNewAPIListOrder: true,
        },
      })
    } catch (error) {}
  }

const pendingPromises = new Map()
const listEventId = new Set()
const debouncedGetEventAndOrder = debounce(async (dispatch, getState, eventIds, isByAdmin) => {
  try {
    const { event } = getState()
    const { eventAndOrderById = [] } = event
    const updatedEventAndOrderById = [...eventAndOrderById]

    // Tạo một mảng các Promise cho mỗi eventId
    const promises = Array.from(listEventId).map(async (eventId) => {
      const { msgResp: _event } = await eventApi.getEventDetail({
        eid: eventId,
        isGetDeliveryZones: true,
        isByAdmin,
      })
      const { msgResp: _orderByEid } = await orderApi.getListOrdersByEventId(eventId, isByAdmin)

      let newEventAndOrderById = {
        ..._event,
        orders: _orderByEid,
      }

      if (_orderByEid && _orderByEid.length > 0) {
        const orderNo = new Set(_orderByEid.map((order) => order.eOrderNo))
        const orderRef = new Set(_orderByEid.map((order) => order.ref))

        if (orderNo.size !== orderRef.size || Math.max(...orderNo) !== orderRef.size) {
          const { msgCode } = await orderApi.updateOrderNo(eventId)
          if (msgCode && Number(msgCode) % 100 === 0) {
            const { msgResp: updatedOrders } = await orderApi.getListOrdersByEventId(eventId)
            newEventAndOrderById = {
              ..._event,
              orders: updatedOrders,
            }
          }
        }
      }

      return newEventAndOrderById
    })

    // Chờ tất cả các Promise hoàn thành
    const results = await Promise.all(promises)

    // Cập nhật state với kết quả mới
    results.forEach((newEventAndOrderById) => {
      const index = updatedEventAndOrderById.findIndex(
        (item) => item.id === newEventAndOrderById.id
      )
      if (index !== -1) {
        updatedEventAndOrderById[index] = newEventAndOrderById
      } else {
        updatedEventAndOrderById.push(newEventAndOrderById)
      }
    })

    dispatch({
      type: GET_EVENT_AND_ORDER_BUY_EID,
      payload: {
        eventAndOrderById: updatedEventAndOrderById,
        callNewAPIListOrder: false,
        errorGetEventAndOrder: null,
      },
    })

    // Xóa các Promise đã hoàn thành khỏi Map
    pendingPromises.clear()
    listEventId.clear()
  } catch (e) {
    dispatch({
      type: GET_EVENT_AND_ORDER_BUY_EID,
      payload: { errorGetEventAndOrder: e },
    })
  } finally {
    dispatch({
      type: GET_EVENT_AND_ORDER_BUY_EID,
      payload: { loadingGetEventAndOrder: false },
    })
  }
}, 100) // Debounce trong 100ms

export const getEventAndOrder =
  (eventId, isByAdmin = false) =>
  async (dispatch, getState) => {
    listEventId.add(eventId)
    // Nếu đã có một Promise đang chờ xử lý cho eventId này, trả về Promise đó
    if (pendingPromises.has(eventId)) {
      return pendingPromises.get(eventId)
    }

    const promise = new Promise((resolve) => {
      debouncedGetEventAndOrder(dispatch, getState, listEventId, isByAdmin)
      resolve()
    })

    // Lưu Promise vào Map
    pendingPromises.set(eventId, promise)

    return promise
  }

export const getOrderCombinedPayment = (combinedPaymentRef) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { listCombinedPayments = [], eventAndOrderById = [] } = event

    const listEventAndOrder = []

    const exitOrderCombinedPayment = listCombinedPayments.findIndex(
      (item) => item.combinedPaymentRef === combinedPaymentRef
    )

    let orderCombinedPayment
    const uniqueOrderCombinedPayment = []

    if (exitOrderCombinedPayment === -1) {
      const { msgResp } = await eventApi.listInvoicesCombinedPayment(combinedPaymentRef)
      const { orders } = msgResp

      // eslint-disable-next-line no-unused-vars
      orderCombinedPayment = orders.map(({ ref, eid, eTitle }) => ({ ref, eid, eTitle }))

      orderCombinedPayment.map((x) =>
        uniqueOrderCombinedPayment.filter((a) => a.ref === x.ref && a.eid === x.eid).length > 0
          ? null
          : uniqueOrderCombinedPayment.push(x)
      )
    } else {
      // eslint-disable-next-line prefer-destructuring
      orderCombinedPayment = listCombinedPayments[exitOrderCombinedPayment].orderCombinedPayment
    }

    if (uniqueOrderCombinedPayment.length) {
      dispatch({
        type: GET_COMBINED_PAYMENT,
        payload: {
          listCombinedPayments: [
            ...listCombinedPayments,
            { combinedPaymentRef, orderCombinedPayment: uniqueOrderCombinedPayment },
          ],
        },
      })
    }

    for (const element of orderCombinedPayment) {
      const { eid } = element
      const exitEvenAndOrder = eventAndOrderById.findIndex((item) => item.id === eid)
      const exitListEO = listEventAndOrder.findIndex((item) => item.id === eid)

      if (exitEvenAndOrder === -1 && exitListEO === -1) {
        const { msgResp: _event } = await eventApi.getEventById(eid)
        const { msgResp: _orderByEid } = await orderApi.getListOrdersByEventId(eid)

        const newEventAndOrderById = {
          ..._event,
          orders: _orderByEid,
        }
        listEventAndOrder.push(newEventAndOrderById)
      }
    }
    if (listEventAndOrder.length) {
      const eventMap = new Map()
      const combinedList = [...eventAndOrderById, ...listEventAndOrder]

      combinedList.forEach((item) => {
        if (
          !eventMap.has(item.id) ||
          (item.orders && item.orders.length > eventMap.get(item.id).orders.length)
        ) {
          eventMap.set(item.id, item)
        }
      })

      // Chuyển Map thành mảng
      const uniqueEventAndOrderById = Array.from(eventMap.values())

      dispatch({
        type: GET_EVENT_AND_ORDER_BUY_EID,
        payload: {
          eventAndOrderById: uniqueEventAndOrderById,
          callNewAPIListOrder: false,
          errorGetEventAndOrder: null,
        },
      })
    }
  } catch (e) {}
}

export const getListEventOrder = (listEid) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { eventAndOrderById = [] } = event

    // Lọc các eventId chưa tồn tại trong state
    const existingEids = new Set(eventAndOrderById.map((item) => item.id))
    const uniqueEids = listEid.filter((eid) => !existingEids.has(eid))

    if (uniqueEids.length === 0) return

    // Thực hiện các lệnh gọi API song song
    const eventPromises = uniqueEids.map((eid) => eventApi.getEventById(eid))
    const orderPromises = uniqueEids.map((eid) => orderApi.getListOrdersByEventId(eid))

    const [eventsResponses, ordersResponses] = await Promise.all([
      Promise.all(eventPromises),
      Promise.all(orderPromises),
    ])

    const newEventAndOrderById = eventsResponses.map((eventResponse, index) => ({
      ...eventResponse.msgResp,
      orders: ordersResponses[index].msgResp,
    }))

    const combinedList = [...eventAndOrderById, ...newEventAndOrderById]

    // Kết hợp và lọc ra các event duy nhất
    const eventMap = new Map()
    combinedList.forEach((item) => {
      if (
        !eventMap.has(item.id) ||
        (item.orders && item.orders.length > eventMap.get(item.id).orders.length)
      ) {
        eventMap.set(item.id, item)
      }
    })

    const uniqueEventAndOrderById = Array.from(eventMap.values())

    dispatch({
      type: GET_EVENT_AND_ORDER_BUY_EID,
      payload: {
        eventAndOrderById: uniqueEventAndOrderById,
        callNewAPIListOrder: false,
        errorOrderDetail: null,
      },
    })
  } catch (error) {
    // Có thể dispatch một action để xử lý lỗi tùy theo yêu cầu
    dispatch({
      type: 'GET_EVENT_ORDER_ERROR',
      payload: { error },
    })
  } finally {
    dispatch({
      type: GET_EVENT_AND_ORDER_BUY_EID,
      payload: {
        loadingGetListEventOrder: false,
      },
    })
  }
}

export const getListCombinedPayment = (listCombinedPaymentRef) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { listCombinedPayments = [] } = event

    // Lọc các combinedRef chưa tồn tại trong listCombinedPayments hiện tại
    const existingRefs = new Set(listCombinedPayments.map((item) => item.combinedPaymentRef))
    const refsToFetch = listCombinedPaymentRef.filter((ref) => !existingRefs.has(ref))

    if (refsToFetch.length === 0) return

    // Thực hiện các lệnh gọi API song song
    const fetchPromises = refsToFetch.map((ref) =>
      eventApi
        .listInvoicesCombinedPayment(ref)
        .then((response) => ({ ref, response }))
        .catch((error) => ({ ref, error }))
    )
    const results = await Promise.all(fetchPromises)

    const newListCombinedPayment = []
    const listEidSet = new Set()

    results.forEach(({ ref, response, error }) => {
      if (error) {
        console.error(`Error fetching combined payment for ref ${ref}:`, error)
        return
      }

      const { msgResp } = response
      const { orders, deliveryBookings } = msgResp

      const uniqueOrders = []
      const orderSet = new Set()
      orders.forEach(({ ref: orderRef, eid, eTitle }) => {
        const key = `${ref}-${eid}`
        if (!orderSet.has(key)) {
          orderSet.add(key)
          uniqueOrders.push({ ref: orderRef, eid, eTitle })
          listEidSet.add(eid)
        }
      })

      newListCombinedPayment.push({
        combinedPaymentRef: ref,
        orderCombinedPayment: uniqueOrders,
        deliveryCombinedPayment: deliveryBookings,
      })
    })

    if (newListCombinedPayment.length > 0) {
      dispatch({
        type: GET_COMBINED_PAYMENT,
        payload: {
          listCombinedPayments: [...listCombinedPayments, ...newListCombinedPayment],
        },
      })

      const listEid = Array.from(listEidSet)
      await dispatch(getListEventOrder(listEid))
      listEidSet.clear()
    }
  } catch (error) {
    // Có thể dispatch một action để xử lý lỗi tại đây nếu cần
  } finally {
    dispatch({
      type: GET_COMBINED_PAYMENT,
      payload: {
        loadingGetListCombinedPayment: false,
      },
    })
  }
}

export const updateOrderCombinedPayment =
  (combinedPaymentRef, status, buyerStatus) => async (dispatch, getState) => {
    try {
      const { event } = getState()
      const { listCombinedPayments = [], eventAndOrderById = [] } = event

      const exitOrderCombinedPayment = listCombinedPayments.findIndex(
        (item) => item.combinedPaymentRef === combinedPaymentRef
      )

      let orderCombinedPayment = []
      if (exitOrderCombinedPayment !== -1) {
        // eslint-disable-next-line prefer-destructuring
        orderCombinedPayment = listCombinedPayments[exitOrderCombinedPayment].orderCombinedPayment
        // get EventAndOrder relate
        orderCombinedPayment.forEach(async (element) => {
          const { eid, ref } = element
          const exitEvenAndOrder = eventAndOrderById.findIndex((item) => item.id === eid)

          if (exitEvenAndOrder !== -1) {
            const updatedOrders = eventAndOrderById[exitEvenAndOrder].orders.map((order) =>
              order.ref === ref ? { ...order, status, buyerStatus } : order
            )

            eventAndOrderById[exitEvenAndOrder] = {
              ...eventAndOrderById[exitEvenAndOrder],
              orders: updatedOrders,
            }
          }
        })
      }
      dispatch(clearDataPurchaseDetail())
      dispatch(clearMyOrder())
      dispatch(clearDeliveryDetail())
      dispatch(clearPastPurchasedMember())
      dispatch({
        type: UPDATE_STATUS_ORDER,
        payload: {
          eventAndOrderById,
          callNewAPIListOrder: true,
        },
      })
    } catch (e) {}
  }

export const clearListCombinedPayment = () => (dispatch) => {
  try {
    dispatch({
      type: GET_COMBINED_PAYMENT,
      payload: { listCombinedPayments: [] },
    })
  } catch (e) {}
}

export const getEventById = (eventId) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents } = event
    let eventNeed = {}
    const indexInMyEvents = myEvents?.filter((item) => item.id === eventId)
    if (indexInMyEvents) {
      eventNeed = indexInMyEvents[0]
    } else {
      await eventApi.getEventById(eventId).then(({ msgResp }) => {
        eventNeed = msgResp
      })
    }
    dispatch({
      type: GET_EVENT_BY_ID,
      payload: { eventById: eventNeed },
    })
  } catch (error) {}
}

export const createEvent = (data) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents, allEvents } = event
    const newMyEvents = myEvents
    const newAllEvents = allEvents
    const { msgResp } = await orderApi.getListOrdersByEventId(data.id)
    const newEvent = {
      ...data,
      orders: msgResp,
    }
    newMyEvents.unshift(newEvent)
    newAllEvents.unshift(newEvent)
    dispatch({
      type: CREATE_EVENT,
      payload: { allEvents: [...newAllEvents], myEvents: [...newMyEvents] },
    })
  } catch (error) {}
}

export const updateEvent = (data) => (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents, allEvents } = event
    // await eventApi.updateEvent(data)
    const newMyEvents = myEvents
    const newAllEvents = allEvents

    const indexInMyEvents = newMyEvents.findIndex((item) => item.id === data.id)
    const indexInAllEvent = newAllEvents.findIndex((item) => item.id === data.id)

    if (indexInMyEvents !== -1) {
      newMyEvents[indexInMyEvents] = { ...newMyEvents[indexInMyEvents], ...data }
    } else {
      newMyEvents.push(data)
    }

    if (indexInAllEvent !== -1) {
      newAllEvents[indexInAllEvent] = { ...newAllEvents[indexInAllEvent], ...data }
    } else {
      newAllEvents.push(data)
    }
    dispatch({
      type: UPDATE_EVENT,
      payload: { myEvents: [...newMyEvents] },
    })
  } catch (error) {}
}
export const updateStatusEvent = () => (dispatch) => {
  dispatch({
    type: UPDATE_STATUS_EVENT,
    payload: { allEvents: [] },
  })
}

export const deleteEvent = (id) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents, allEvents, eventAndOrderById } = event

    const newMyEvents = myEvents
    const newAllEvents = allEvents
    const newEventAndOrderById = eventAndOrderById

    const indexInMyEvents = newMyEvents.findIndex((item) => item.id === id)
    const indexInAllEvent = newAllEvents.findIndex((item) => item.id === id)
    const indexInEventAndOrderById = newEventAndOrderById.findIndex((item) => item.id === id)

    if (indexInMyEvents !== -1) {
      newMyEvents.splice(indexInMyEvents, 1)
    }

    if (indexInAllEvent !== -1) {
      newAllEvents.splice(indexInAllEvent, 1)
    }
    if (indexInEventAndOrderById !== -1) {
      newEventAndOrderById.splice(indexInEventAndOrderById, 1)
    }
    dispatch(clearPastPurchasedMember())
    dispatch({
      type: DELETE_EVENT,
      payload: {
        myEvents: [...newMyEvents],
        allEvents: [...newAllEvents],
        eventAndOrderById: [...newEventAndOrderById],
      },
    })
  } catch (error) {}
}

export const getEventsClosePerMonthByHost = (data, groupId) => async (dispatch) => {
  let listEventsClosePerMonthByHost = []

  try {
    const response = await eventApi.getEventsClosePerMonthByHost(data, groupId)
    if (response?.msgCode) {
      listEventsClosePerMonthByHost = response.msgResp
    }
    dispatch({
      type: GET_EVENTS_CLOSE_PER_MONTH_BY_HOST,
      payload: {
        listEventsClosePerMonthByHost,
      },
    })
  } catch (error) {}
}

export const getEventsCollectionPerMonthByHost = (data, groupId) => async (dispatch) => {
  let listEventsCollectionPerMonthByHost = []

  try {
    const response = await eventApi.getEventsCollectionPerMonthByHost(data, groupId)
    if (response?.msgCode) {
      listEventsCollectionPerMonthByHost = response.msgResp
    }
    dispatch({
      type: GET_EVENTS_CLOSE_PER_MONTH_BY_HOST,
      payload: {
        listEventsCollectionPerMonthByHost,
      },
    })
  } catch (error) {}
}

export const getAllEventsByGroup = () => async (dispatch) => {
  try {
    const { msgResp } = await eventApi.getAllEventsByGroup()
    dispatch({
      type: GET_ALL_EVENTS_BY_GROUP,
      payload: {
        allEvents: msgResp,
      },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const updateDeliveryCommentDesktop = (ref, comment) => async (dispatch, getState) => {
  const { event } = getState()
  const { listEventAndDelivery } = event
  const updatedListDelivery = listEventAndDelivery?.deliveryBookings?.map((item) => {
    if (item.ref === ref) {
      return { ...item, comment }
    }
    return item
  })

  const newListEventAndDelivery = {
    ...listEventAndDelivery,
    deliveryBookings: updatedListDelivery,
  }

  dispatch({
    type: UPDATE_DELIVERY_COMMENT_DESKTOP,
    payload: {
      listEventAndDelivery: newListEventAndDelivery,
    },
  })
}

const event = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_EVENTS: {
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(),
        ...action.payload,
      }
    }
    case CLEAR_EVENT: {
      return {
        ...state,
        newGroup: null,
      }
    }
    case GET_LIST_EVENT_PUBLIC:
    case UPDATE_DELIVERY_COMMENT_DESKTOP:
    case GET_MY_EVENTS:
    case GET_LIST_EVENT_GROUP_ID_BY_HOST:
    case GET_LIST_SCHEDULED_EVENTS:
    case GET_LIST_SCHEDULED_EVENTS_ERROR:
    case DELETE_LIST_SCHEDULED_EVENTS:
    case GET_EVENT_AND_ORDER_BUY_EID:
    case GET_EVENT_BY_ID:
    case GET_SHARE_EVENT_CODE:
    case UPDATE_STATUS_ORDER:
    case CREATE_EVENT:
    case UPDATE_EVENT:
    case DELETE_EVENT:
    case UPDATE_EVENT_ORDER:
    case CLEAR_EVENT_ORDER:
    case CLEAR_MY_EVENTS:
    case CLEAR_ALL_EVENTS:
    case GET_ALL_EVENTS_OPENING_REQUEST:
    case GET_ALL_EVENTS_OPENING_SUCCESS:
    case GET_ALL_EVENTS_OPENING_FAIL:
    case UPDATE_TOTAL_ORDER_COUNT_WHEN_ORDER:
    case UPDATE_TOTAL_ORDER_COUNT_WHEN_CANCEL_ORDER:
    case UPDATE_TOTAL_ORDER_COUNT_WHEN_UPDATE_ORDER:
    case UPDATE_STATUS_EVENT:
    case GET_COMBINED_PAYMENT:
    case GET_EVENTS_CLOSE_PER_MONTH_BY_HOST:
    case GET_EVENTS_COLLECTION_PER_MONTH_BY_HOST:
    case GET_LIST_EVENT_AND_DELIVERY:
    case GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID:
    case GET_LIST_EVENTS_UN_AUTH:
    case GET_ALL_EVENTS_BY_GROUP:
    case CLEAR_ALL_EVENTS_OPENING:
    case GET_LIST_EVENT_VALID:
    case PAYMENT_SCHEDULE_EVENT:
    case UPDATE_DELIVERY_PAYMENT_STATUS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default event
