import PropTypes from 'prop-types'
import React from 'react'

import InputField from '_components/Input'
import { GROUP_IS_PRIVATE, GROUP_IS_PUBLIC } from '_utils/constant'

const GroupSetting = ({
  groupType,
  defaultRole,
  autoApproval,
  onChangeRadio,
  register,
  accessMemberList,
}) => (
  <React.Fragment>
    <p className='c-txt-13 text-secondary text-center'>
      <strong>Group settings</strong>
    </p>
    <div className='box-setting'>
      <div className='c-form'>
        <div className='form-group'>
          <div className='row'>
            <div className='col-4'>
              <div className='d-flex h-100 justify-content-center'>
                <label className='text-black text-center my-auto' style={{ fontSize: 14 }}>
                  Group type
                </label>
              </div>
            </div>
            <div className='col-4'>
              <div
                className={`custom-control custom-radio p-1 ${
                  groupType === GROUP_IS_PUBLIC ? 'active' : ''
                }`}
              >
                <input
                  type='radio'
                  name='groupVisibility'
                  id='groupVisibilityPublic'
                  value={groupType}
                  className='d-inline-block opacity-0 w-100 h-100 position-absolute top-0 start-0'
                  onChange={() => onChangeRadio('groupType', GROUP_IS_PUBLIC)}
                  checked={groupType === GROUP_IS_PUBLIC}
                />
                <label
                  className='custom-control-label ps-3 text-black fw-bold d-flex align-items-center'
                  htmlFor='groupVisibilityPublic'
                >
                  Public
                </label>
                <p>Only members of group can participate in group-buy</p>
              </div>
            </div>
            <div className='col-4'>
              <div
                className={`custom-control custom-radio p-1 ${
                  groupType === GROUP_IS_PRIVATE ? 'active' : ''
                }`}
              >
                <input
                  type='radio'
                  name='groupVisibility'
                  id='groupVisibilityPrivate'
                  value={groupType}
                  className='d-inline-block opacity-0 w-100 h-100 position-absolute top-0 start-0'
                  onChange={() => onChangeRadio('groupType', GROUP_IS_PRIVATE)}
                  checked={groupType === GROUP_IS_PRIVATE}
                />
                <label
                  className='custom-control-label ps-3 text-black fw-bold d-flex align-items-center'
                  htmlFor='groupVisibilityPrivate'
                >
                  Private (Free)
                </label>
                <p>Anyone in Co-bee can participate in Group-Buy.</p>
              </div>
            </div>
            {/* <div className='col-4'>
              <div className={`custom-control custom-radio p-1 ${groupType === 2 ? 'active' : ''}`}>
                <input
                  type='radio'
                  name='groupVisibility'
                  id='groupVisibilityHidden'
                  value={groupType}
                  onChange={() => onChangeRadio('groupType', 2)}
                  className='d-inline-block opacity-0 w-100 h-100 position-absolute top-0 start-0'
                  checked={groupType === 2}
                />
                <label
                  className='custom-control-label ps-3 text-black fw-bold d-flex align-items-center'
                  htmlFor='groupVisibilityHidden'
                >
                  Hidden
                </label>
                <p>Group-buy is hidden and only members can participate</p>
              </div>
            </div> */}
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-4'>
              <div className='d-flex justify-content-center h-100'>
                <label className='text-black my-auto text-center' style={{ fontSize: 14 }}>
                  Default Permission
                </label>
              </div>
            </div>
            <div className='col-4'>
              <div
                className={`custom-control custom-radio p-1 ${defaultRole === 1 ? 'active' : ''}`}
              >
                <input
                  type='radio'
                  name='groupDefaultUserPermission'
                  id='groupDefaultUserPermissionMember'
                  value={defaultRole}
                  className='d-inline-block opacity-0 w-100 h-100 position-absolute top-0 start-0'
                  onChange={() => onChangeRadio('defaultRole', 1)}
                  checked={defaultRole === 1}
                />
                <label
                  className='custom-control-label ps-3 text-black fw-bold d-flex align-items-center'
                  htmlFor='groupDefaultUserPermissionMember'
                >
                  Member
                </label>
                <p>All member by default don’t have the right to host group-buy</p>
              </div>
            </div>
            <div className='col-4'>
              <div
                className={`custom-control custom-radio p-1 ${defaultRole === 2 ? 'active' : ''}`}
              >
                <input
                  type='radio'
                  name='groupDefaultUserPermission'
                  id='groupDefaultUserPermissionHost'
                  value={defaultRole}
                  className='d-inline-block opacity-0 w-100 h-100 position-absolute top-0 start-0'
                  onChange={() => onChangeRadio('defaultRole', 2)}
                  checked={defaultRole === 2}
                />
                <label
                  className='custom-control-label ps-3 text-black fw-bold d-flex align-items-center'
                  htmlFor='groupDefaultUserPermissionHost'
                >
                  Host
                </label>
                <p>All members by default can host group-buy events</p>
              </div>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-4'>
              <div className='d-flex justify-content-center h-100'>
                <label className='text-black my-auto text-center' style={{ fontSize: 14 }}>
                  New Joiners Verification
                </label>
              </div>
            </div>
            <div className='col-4'>
              <div
                className={`custom-control custom-radio p-1 cursor-pointer ${
                  autoApproval === 0 ? 'active' : ''
                }`}
              >
                <input
                  type='radio'
                  name='groupJoinVerification'
                  id='groupJoinVerificationAdminApproval'
                  value={autoApproval}
                  onChange={() => onChangeRadio('autoApproval', 0)}
                  checked={autoApproval === 0}
                />
                <label
                  htmlFor='groupJoinVerificationAdminApproval'
                  className='custom-control-label ps-3 text-black fw-bold d-flex align-items-center'
                >
                  Yes
                </label>
                <p>Requires Admin to verify new joiners before admitting them to group</p>
              </div>
            </div>
            <div className='col-4'>
              <div
                className={`custom-control custom-radio p-1 cursor-pointer ${
                  autoApproval === 1 ? 'active' : ''
                }`}
              >
                <input
                  type='radio'
                  name='groupJoinVerification'
                  id='groupJoinVerificationAutoApproval'
                  value={autoApproval}
                  onChange={() => onChangeRadio('autoApproval', 1)}
                  checked={autoApproval === 1}
                />
                <label
                  className='custom-control-label ps-3 text-black fw-bold d-flex align-items-center'
                  htmlFor='groupJoinVerificationAutoApproval'
                >
                  No
                </label>
                <p>Auto admit new joiners into group without the need for verification.</p>
              </div>
            </div>

            {autoApproval === 1 && (
              <div id='passcodeHtmlBlock' className='c-form col-8 ms-auto my-2'>
                <p className='c-txt-12 mb-10'>
                  Passcode (Leave this blank if you dont need verification)
                </p>
                <div className='form-group'>
                  <InputField
                    className='form-control'
                    id='passCode'
                    name='passCode'
                    type='text'
                    register={register}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-4'>
              <div className='d-flex justify-content-center h-100'>
                <label className='text-black my-auto text-center' style={{ fontSize: 14 }}>
                  Member Privacy
                </label>
              </div>
            </div>
            <div className='col-4'>
              <div
                className={`custom-control custom-radio p-1 cursor-pointer ${
                  accessMemberList === 1 ? 'active' : ''
                }`}
              >
                <input
                  type='radio'
                  name='groupDefaultAccessMemberList'
                  id='groupDefaultAccessMemberListVi'
                  value={accessMemberList}
                  onChange={() => onChangeRadio('accessMemberList', 1)}
                  checked={accessMemberList === 1}
                />
                <label
                  className='custom-control-label ps-3 text-black fw-bold d-flex align-items-center'
                  htmlFor='groupDefaultAccessMemberListVi'
                >
                  Yes
                </label>
                <p>All members can see who is in the group</p>
              </div>
            </div>
            <div className='col-4'>
              <div
                className={`custom-control custom-radio p-1 cursor-pointer ${
                  accessMemberList === 0 ? 'active' : ''
                }`}
              >
                <input
                  type='radio'
                  name='groupDefaultAccessMemberList'
                  id='groupDefaultAccessMemberListIn'
                  value={accessMemberList}
                  onChange={() => onChangeRadio('accessMemberList', 0)}
                  checked={accessMemberList === 0}
                />
                <label
                  className='custom-control-label ps-3 text-black fw-bold d-flex align-items-center'
                  htmlFor='groupDefaultAccessMemberListIn'
                >
                  No
                </label>
                <p>All members cannot see who is in the group</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
)

GroupSetting.propTypes = {
  autoApproval: PropTypes.number,
  defaultRole: PropTypes.number,
  groupType: PropTypes.number,
  onChangeRadio: PropTypes.func,
  register: PropTypes.object,
}

export default GroupSetting
