import LoadingOverlay from '_components/LoadingOverlay'
import ShopList from '_pages/Shop/ShopList'
import { getAllEventsOpening, getListEventsInGroupPublic } from '_redux/modules/event'
import { getMyOrders } from '_redux/modules/order'
import { ITEMS_PER_PAGE } from '_utils/constant'
import { uniqueEvents } from '_utils/function'
import { getUserInfo } from '_utils/localData'
import React, { createContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListAllEvent from '../Event/ListAllEvent'

export const AllPageContext = createContext()

function AllPage() {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [currentPageShop, setCurrentPageShop] = useState(1)
  const [currentPageEvent, setCurrentPageEvent] = useState(1)
  const [searchShop, setSearchShop] = useState('')
  const [searchEvent, setSearchEvent] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const { allEvents, eventsPublic } = useSelector((state) => state.event)
  const { listShop } = useSelector((state) => state.shop)

  const userInfo = getUserInfo()
  const myGroups = userInfo?.groups || []

  // for user can see all events from group private and public
  // allEvents: list of all events from group private and public and from shop
  // eventsPublic: list of all events from group public
  // if events from group public, group private is the same
  // then it will be filtered to unique events
  const combinedListEvents = uniqueEvents(allEvents, eventsPublic)

  // Hàm kiểm tra xem user có phải là thành viên của group hay không
  const isUserMemberOfGroup = (groupId) => myGroups.some((g) => g.gid === groupId)

  // Lọc sự kiện
  const filteredEvents = combinedListEvents.filter((event) => {
    if (isUserMemberOfGroup(event.groupId)) {
      return true
    }
    return false
  })

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      try {
        setLoading(true)
        await Promise.all([
          dispatch(getListEventsInGroupPublic()),
          // dispatch(getListShopPaid()),
          dispatch(getAllEventsOpening),
        ])
      } catch (error) {
        setErrorMessage(error.message)
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    fetchData()
    dispatch(getMyOrders)

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (!searchEvent) {
      setCurrentPageEvent(1)
    }
    if (!searchShop) {
      setCurrentPageShop(1)
    }
  }, [searchEvent, searchShop])

  const numberOfPagesEvent = Math.ceil(filteredEvents.length / ITEMS_PER_PAGE)
  const numberOfPagesShop = Math.ceil(listShop.length / ITEMS_PER_PAGE)

  const onPageChangeEvent = (page) => setCurrentPageEvent(page)

  const onPageChangeShop = (page) => setCurrentPageShop(page)

  const onSearchShop = (value) => setSearchShop(value)

  const onSearchEvent = (value) => setSearchEvent(value)

  const sharedValues = {
    shop: {
      listShop,
      numberOfPagesShop,
      onPageChangeShop,
      currentPageShop,
      onSearchShop,
      searchShop,
    },
    event: {
      listEvent: filteredEvents,
      numberOfPagesEvent,
      onPageChangeEvent,
      currentPageEvent,
      onSearchEvent,
      searchEvent,
    },
  }

  if (loading) return <LoadingOverlay />

  if (!loading && errorMessage) return <div>{errorMessage}</div>

  if (!loading && !listShop?.length && !filteredEvents?.length) {
    return (
      <h6 className='text-center fw-bold f-24 w-100 mt-5' style={{ fontSize: '1rem' }}>
        No Event List {listShop?.length ? '& Shop List' : null}
      </h6>
    )
  }

  return (
    <div className='all-page container'>
      <AllPageContext.Provider value={sharedValues}>
        {/* Event list */}
        {filteredEvents?.length ? (
          <>
            <ListAllEvent />
          </>
        ) : null}

        {/* Shop list */}
        {listShop?.length ? (
          <>
            <hr style={{ margin: '5rem auto', borderTop: '1px solid black' }} /> <ShopList />
          </>
        ) : null}
      </AllPageContext.Provider>
    </div>
  )
}

export default AllPage
