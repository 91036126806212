import axiosClient from './axiosClient'

const orderApi = {
  getListOrdersByBuyer: (groupId) => {
    const url = '/list-orders-by-buyer'
    if (groupId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['group-id'] = groupId
        return config
      })
    }
    return axiosClient.get(url)
  },
  getOrderByRef: (ref) => {
    const url = `/list-orders-by-ref?ref=${ref}`
    return axiosClient.get(url)
  },
  createOrder: (data) => {
    const url = '/create-order'
    return axiosClient.post(url, data)
  },
  getListOrdersByEventId: (eventId, isByAdmin = false) => {
    const endPoint = isByAdmin ? '/get-list-order-by-admin' : '/list-orders-by-event'
    const url = `${endPoint}?eid=${eventId}`
    return axiosClient.get(url)
  },
  updateOrderBuyerStatus: (ref, data) => {
    const url = `/update-order-buyer-status?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  updateOrderBuyerInfo: (ref, data) => {
    const url = `/update-order-buyer-info?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  updateOrderBuyerStatusWithPaymentScreenshot: (ref, data) => {
    const url = `/update-order-buyer-status?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  updateOrderStatus: (ref, data) => {
    const url = `/update-order-status?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  getListOrderByRef: (ref) => {
    const url = `/list-orders-by-ref?ref=${ref}`
    return axiosClient.get(url)
  },
  updateOrder: ({ oid, data }) => {
    const url = `update-order?ref=${oid}`
    return axiosClient.put(url, data)
  },
  updateOrderNo: (id) => {
    const url = `/update-order-no?eid=${id}`
    return axiosClient.patch(url)
  },
  updateOrderStatusAndBuyerStatus: (ref, data) => {
    const url = `/update-order-status?ref=${ref}`

    return axiosClient.patch(url, data)
  },
  updateInvoicesPlacerStatus: (data) => {
    const url = '/update-invoices-placer-status'
    return axiosClient.patch(url, data)
  },
  updateInvoicesStatus: (data) => {
    const url = '/update-invoices-status'
    return axiosClient.patch(url, data)
  },
}

export default orderApi
